.register-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #692aa9;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.large-hook {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-hook img {
  height: 700px;
}

.form-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form-container {
  position: relative;
  border-radius: 50px;
  height: 500px;
  width: 70%;
  padding: 30px 50px;
  background-color: #e8d1ff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.home-link-container {
  position: absolute;
  top: 25px;
  left: 50px;
}

.home-link {
  text-decoration: none;
  color:  #d16dff;
  font-weight: 500;
}

.register-heading {
  color: #2a2a2a;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.email-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.email-icon-register {
  font-size: 3rem;
  color: #d16dff;
}

.registering-email {
  margin: 10px;
}

.register-form {
  height: 80%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 1;
}

.register-button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 5px;
  width: 100%;
  /* height: 2.2vw; */
  height: 30px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8142c0;
  background: #8142c0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

.register-button:focus {
  outline: none;
}

.register-button:hover {
  background: #9952e1;
  border: 3px solid #9952e1;
}

.span-error-register {
  color: red;
  font-size: 0.85rem;
  text-align: start;
}

@media (max-width: 1200px) {
  .large-hook {
    display: none;
  }

  .form-container {
    width: 90%;
  }

  .register-form-container {
    height: 600px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .register-form-container {
    border-radius: 10px;
    padding: 0px 20px;
    height: 520px;
    width: 100%;
  }

  .home-link-container {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .registering-email {
    margin: 10px;
    font-size: 1.1rem;
  }
}


@media(max-height:660px) {
  .register-bg {
    position: static;
  }
}

.country-selector {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.country-input {
  margin-right: 10px;
  font-weight: 500;
  color: #707070;
  font-size: 1.2rem;
}