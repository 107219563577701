.dashboard-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #692aa6;
}

.dashboard {
  width: 100%;
}

.dashboard-logo {
}

.dashboard-logo img {
  height: 70px;
  /* margin: 10px 50px; */
}

.dashboard-user-account {
  /* margin: 0px 50px; */
  font-size: 1rem;
  font-weight: 500;
  color: lightgrey;
  cursor: pointer;
}

.dashboard-user-greeting {
  margin: 10px 20px 40px 20px;
  padding: 0px;
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-align: center;
}

.dashboard-heading {
  width: 80%;
  margin: 20px 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dashboard-heading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-tier-progression {
  margin: 0 auto auto auto;
  padding: 20px;
  border-radius: 50px;
  border: 2px solid white;
  width: min-content;
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
}

.dashboard-progress{
  color: #cf6679;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 40px;
}

.dashboard-tiers {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  column-gap: 10px;
  row-gap: 40px;
  justify-items: center;
  align-items: center;
}

.dashboard-tier-container {
  display: grid;
  grid-template-rows: 50px 70px 40px;
  justify-items: center;
  align-items: center;
}

.dashboard-tier.icon {
  color: #8c8c8c;
  font-size: 3rem;
}

.dashboard-tier.username {
  color: #8c8c8c;
  font-size: 1rem;
}

.dashboard-tier.heading {
  color: white;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.dashboard-tier.requirement {
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

.dashboard-tier-link {
  margin: auto 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.dashboard-user-link {
  color: white;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.dashboard-link-header {
  text-align: center;
}

.user-shareable-link {
  color: #cf6679;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-shareable-link:hover {
  transform: scale(1.01);
}

.user-shareable-link.container {
  cursor: pointer;
  height: 100px;
  width: 600px;
  border-radius: 30px;
  border: 3px solid #d16dff;
  background-color: #e8d1ff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.user-shareable-link.container:hover {
  background-color: #f1e2ff;
}

.Server-Message{
  margin-top: 10px;
  color: #d16dff;
}

.dashboard-copy-alert{
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 300px;
  background-color: #dcffcc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clipboard-message{
  color: grey;
  font-size: 1.1rem;
  font-weight: 600;
}

.social-share-container{
  display: flex;
  flex-flow: row nowrap;
}

.dashboard-count-container {
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.dashboard-count-message {
  margin: 0 10px;
}

.dasboard-referral-count {
  color: #dcffcc;
  margin: 0 10px;
  font-weight: 900;
  font-size: 2.5rem;
}

.dashboard-username-container {
  position: relative;
  height: 150px;
  width: 600px;
  border-radius: 30px;
  border: 3px solid #d16dff;
  background-color: #e8d1ff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.disabled-overlay {
  position: absolute;
  border: 3px solid #d16dff;
  border-radius: 30px;
  width: 600px;
  height: 150px;
  z-index: 3;
}

.disabled-overlay:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dashboard-update-username {
  display: flex;
  flex-flow: row nowrap;
}

.dashboard-username-header {
  margin-bottom: 15px;
  margin-top: 0px;
  text-align: center;
  color: #692aa6;
  font-weight: 500;
  font-size: 1.2rem;
}

.username-button {
  margin-left: 20px;
  width: 120px;
  height: 35px;
  outline: none;
  background-color: #692aa6;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.username-button:hover {
  background-color: #8142c0;
}

.username-button:active {
  background-color: #8142c0;
}

@media (max-width: 1460px) {
  .dashboard-container {
    height: auto;
  }

  .dashboard-tier-link {
    margin-bottom: 100px;
  }

    .dashboard-tier-progression {
margin-bottom: 50px;
  }
}

@media (max-width: 900px) {
  .dashboard-tiers {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-tier-progression {
    width: 100%;
      }
}

@media (max-width: 625px) {
  .dashboard-tier.icon {
    font-size: 2.5rem;
  }

  .dashboard-tier.username {
    font-size: 0.8rem;
  }

  .dashboard-tier.heading {
    font-size: 0.9rem;
  }

  .dashboard-tier.requirement {
    font-size: 1rem;
  }

  .user-shareable-link.container {
    width: 90%;
  }

  .dashboard-username-container {
    width: 90%;
    margin: 10px;
  }

  .dashboard-update-username {
    margin: 0 10px;
  }

  .disabled-overlay {
    width: 100%;
  }

  .user-shareable-link {
    font-size: 1rem;
  }

  .dashboard-username-header {
    font-size: 1rem;
  }

  .dashboard-user-greeting {
    font-size: 1.5rem;
  }
}

@media (max-width: 420px) {
  .dashboard-logo img {
    height: 50px;
  }

  .user-shareable-link {
    font-size: 0.9rem;
  }

  .user-shareable-link.container {
    height: 70px;
  }
}

@media (max-width: 340px) {
  .user-shareable-link {
    font-size: 0.8rem;
  }
}

@media(min-width: 2200px) {
  .dashboard-tier-link {
    justify-content: center;
  }

  .dashboard-user-link {
    margin: 0 100px;
  }

  .dashboard-tier-progression{
      margin: 0 100px;
  }
}
