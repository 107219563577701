.notfound {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #692aa9;
}

.notfound-contents {
    margin: 50px;
}

.notfound-header {
  font-size: 3rem;
  color: white;
}

.notfound-link {
  color: white;
}
