.referralcta {
    margin: 50px;
}

.referralmaintext {
    margin: 5px 0;
    font-size: 2rem;
    font-weight: 800;
    color: #2D2D2D;
}

.referralsuptext {
    margin: 0 0 15px 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #2D2D2D;
}

/* grid-area classification for tier descriptions */
.bullhorn {
    font-size: 3rem;
    grid-area: bullhorn;
}

.tieroneheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tieronedesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tieronetext {
    display: flex;
    flex-flow: column nowrap;
    grid-area: tierone;
    align-items: center;
}

.tiertwousername {
    font-size: 1.3rem;
    color: #4A47A3;
    grid-area: tiertwousername;
}

.tiertwoheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tiertwodesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}
.tiertwotext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tiertwo;
}

.faatom {
    font-size: 3rem;
    grid-area: faatom;
}

.tierthreeheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tierthreedesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierthreetext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierthree;
}

.tierfourheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tierfourdesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierfourtext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierfour;
}

.fatags {
    font-size: 3rem;
    grid-area: fatags;
}

.tierfiveheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tierfivedesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierfivetext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierfive;
}

.faruler {
    font-size: 3rem;
    grid-area: faruler;
}

.tiersixheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tiersixdesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tiersixtext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tiersix;
}

.fapercent {
    font-size: 3rem;
    grid-area: fapercent;
}

.tiersevenheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tiersevendesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierseventext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierseven;
}

.fagift {
    font-size: 5rem;
    grid-area: fagift;
    color:  #d16dff;
}

.referraltiers {
    padding-top: 15px;
    margin-bottom: 50px;
    display: grid;
    border-radius: 50px;
    border: 3px solid #d16dff;
    background-color: transparent;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    grid-template-areas: 
    "bullhorn tiertwousername faatom"
    "tierone tiertwo tierthree"
    "faruler fapercent fatags"
    "tierfour tierfive tiersix"
    ". fagift ."
    ". tierseven .";
    justify-items: center;
    grid-area: referraltiers;
    align-items: center;
}

.prelaunchcontainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

/* @media (max-width: 1000px) {
    .prelaunchcontainer {
        padding: 1rem 2rem;
    }
} */

/* @media (max-width: 860px) {
    .prelaunchcontainer {
        padding: 1rem 5vw;
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 20px;
        grid-template-areas: 
        "referraltiers"
        "referralcta";
    }
} */

@media (max-width: 560px) {
    .tieroneheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tieronedesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tieronetext {
        display: flex;
        flex-flow: column nowrap;
        grid-area: tierone;
        align-items: center;
    }
    
    
    .tiertwoheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tiertwodesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    .tiertwotext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tiertwo;
    }
    
    .tierthreeheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tierthreedesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierthreetext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierthree;
    }

    .tierfourheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tierfourdesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierfourtext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierfour;
    }

    .tierfiveheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tierfivedesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierfivetext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierfive;
    }

    .tiersixheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tiersixdesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tiersixtext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tiersix;
    }

    .tiersevenheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tiersevendesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierseventext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierseven;
    }
}


@media (max-width: 400px) {

    .referraltiers {
        grid-template-columns: repeat(2, 1fr);
    }
    .referralcta {
        margin: 10px;
    }
}

@media (max-width: 370px) {
    .bullhorn {
        font-size: 2.5rem;
    }
    .fatags {
        font-size: 2.5rem;
    }
    .faatom {
        font-size: 2.5rem;
    }
    .faruler {
        font-size: 2.5rem;
    }
    .fapercent {
        font-size: 2.5rem;
    }
    .fagift {
        font-size: 3rem;
    }
    .tiertwousername {
        font-size: 1rem;
    }
    .tieronedesc {
        font-size: 0.8rem;
    }
    .tiertwodesc{
        font-size: 0.8rem;
    }
    .tierthreedesc {
        font-size: 0.8rem;
    }
    .tierfourdesc {
        font-size: 0.8rem;
    }
    .tierfivedesc {
        font-size: 0.8rem;
    }
    .tiersixdesc {
        font-size: 0.8rem;
    }
    .tiersevendesc {
        font-size: 0.8rem;
    }
}

@media (min-width: 2900px) {
    .referralmaintext {
        font-size: 1.5vw;
    }
    .referralsuptext {
        font-size: 0.8vw;
        line-height: 2.5rem;
    }
    .bullhorn {
        font-size: 3vw;
    }
    .tiertwousername {
        font-size: 1.5vw;
    }
    .fatags {
        font-size: 3vw;
    }
    .tieroneheader {
        font-size: 1.5vw;
    }
    .tiertwoheader {
        font-size: 1.5vw;
    }
    .tierthreeheader {
        font-size: 1.5vw;
    }
    .tierfourheader {
        font-size: 1.5vw;
    }
    .tierfiveheader {
        font-size: 1.5vw;
    }
    .tiersixheader {
        font-size: 1.5vw;
    }
    .tieronedesc {
        font-size: 0.8vw;
    }
    .tiertwodesc {
        font-size:0.8vw;
    }
    .tierthreedesc {
        font-size: 0.8vw;
    }
    .tierfourdesc {
        font-size: 0.8vw;
    }
    .tierfivedesc {
        font-size: 0.8vw;
    }
    .tiersixdesc {
        font-size: 0.8vw;
    }
    .tiersevendesc {
        font-size: 0.8vw;
    }
}