.styledBurger {
  width: 2rem;
  height: 2rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 20;
  cursor: pointer;
}

@media (min-width: 1001px) {
  .styledBurger {
    display: none;
  }
}

.burger {
  width: 2rem;
  height: 0.25rem;
  background-color: #692aa9;
  border-radius: 10px;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.burger:first-child {
  transform: rotate(0deg);
}

.burger:nth-child(2) {
  opacity: 1;
  transform: translateX(0px);
}

.burger:nth-child(3) {
  transform: rotate(0deg);
}

.burgeropen {
  background-color: white;
}

.burgeropen:first-child {
  transform: rotate(45deg);
}

.burgeropen:nth-child(2) {
  opacity: 0;
  transform: translateX(-20px);
}

.burgeropen:nth-child(3) {
  transform: rotate(-45deg);
}

.scrolledBurger{
  background-color: white;
}

