.getintouch {
  grid-column: auto / span 2;
  margin-top: 5px;
  width: 100%;
  height: 2.5vw;
  line-height: 1;
  font-size: 1.5vw;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8142c0;
  background: #8142c0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

.getintouch:focus {
  outline: none;
}

.getintouch:hover {
  background: #9952e1;
  border: 3px solid #9952e1;
}

.getintouch span {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.productionimage img {
  border-radius: 50px;
  max-width: 100%;
  object-fit: cover;
}

.productionimage {
  grid-area: productionimage;
}

.callforsuppliers {
  font-size: 1rem;
  font-weight: 500;
  color: #2d2d2d;
  text-align: center;
  grid-area: callforsuppliers;
}

.supplierform {
  border-radius: 50px;
  padding: 1rem;
  display: grid;
  column-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.supplierformcontainer {
  grid-area: supplierformcontainer;
}

.suppliercontainer {
  margin-bottom: 2rem;
  padding: 0 3rem;
  display: grid;
  grid-template-columns: 3fr 4fr;
  column-gap: 2rem;
  grid-template-areas: "productionimage supplierformcontainer";
  justify-items: stretch;
  align-items: center;
}

@media (max-width: 860px) {
  .suppliercontainer {
    padding: 1rem 2rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "productionimage"
      "supplierformcontainer";
  }
  .getintouch {
    height: 40px;
    font-size: 20px;
  }
}

@media (max-width: 570px) {
  .suppliercontainer {
    padding: 1rem 0.5rem;
  }
  .supplierform {
    display: flex;
    flex-flow: column nowrap;
  }
}

@media (min-width: 2900px) {
  .callforsuppliers {
    font-size: 1vw;
  }
}