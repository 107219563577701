.herosegment {
    background-color: #F1EBF8;
}

.shopsegment {
    background-color: #F1EBF8;
}

.prelaunchsegment {
    background-color: #F1EBF8;
}