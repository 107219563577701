/* Popup style */
.auth-box {
  color: initial;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: space-around;
  text-align: center;
}

.auth-popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.signin-bold {
  color: #8142c0;
  font-size: 1.2rem;
}

.forgot-password {
  color: #cf6679;
  font-weight: 500;
  cursor: pointer;
}

.signup-link {
  color: #d16dff;
  font-weight: 500;
  cursor: pointer;
}

.signInForm {
  height: 45%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.usercircle-signin {
  font-size: 5rem;
  color: #d16dff;
}

.signin-input {
  padding: 100px;
}

.signin-error {
  color: red;
}

.forgot-message-auth{
  color: #d16dff;
}

.signup-span {
  
}

/* .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  } */

.auth-box {
  position: absolute;
  /* float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  width: 250px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}

.auth-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5rem;
  color: #4a4a4a;
  cursor: pointer;
}

.select-popup-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
