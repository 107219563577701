.purpdash {
  width: 2rem;
  height: 0.2rem;
  background-color: #692aa9;
}

/* .loader {
  margin: 0;
  padding: 0;
} */

.heromaintext {
  /* font-size: 2.2vw; */
  font-size: 2em;
  font-weight: 800;
  color: #313131;
  margin: 10px 0;
}

.herosuptext {
  /* font-size: 1.5vw; */
  font-size: 1.3rem;
  font-weight: 500;
  color: #707070;
  line-height: 1.2em;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 1vw;
}

.referral {
  font-size: 0.9rem;
  font-weight: 500;
  /* color: #692aa9; */
  color: #d16dff;
}

.usertype {
  padding-right: 0.5vw;
  align-self: center;
}

.useremail {
  padding-bottom: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  flex-basis: auto;
}

.emailinput {
  width: 100%;
  z-index: 1;
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  /* height: 2.2vw; */
  height: 30px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8142c0;
  background: #8142c0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

.signup:focus {
  outline: none;
}

.signup:hover {
  background: #9952e1;
  border: 3px solid #9952e1;
}

.signup span {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.herotextcta {
  max-width: 85%;
  max-height: 100%;
  grid-area: herotextcta;
}

.heroimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: heroimage;
}

.hero {
  padding: 4vw 6vw 6vw 6vw;
  height: 70vh;
  display: grid;
  grid-template-columns: minmax(400px, 0.8fr) minmax(400px, 1fr);
  grid-template-areas: "herotextcta heroimage";
  align-items: center;
  justify-items: center;
}

.referral {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .hero {
    padding: 2rem;
  }
  .herotextcta {
    max-width: 90%;
  }
  .heromaintext {
    font-size: 1.5em;
  }
  .herosuptext {
    font-size: 1rem;
  }
  .referral {
    font-size: 0.9rem;
  }
}

@media (max-width: 860px) {
  .hero {
    height: 100%;
    padding: 3rem 10vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-areas:
      "heroimage"
      "herotextcta";
    row-gap: 70px;
    align-items: center;
    justify-items: center;
  }
  .herotextcta {
    max-width: 100%;
  }
  .heromaintext {
    font-size: 2em;
  }
  .herosuptext {
    font-size: 1.3rem;
    line-height: 40px;
  }
  .referral {
    font-size: 1em;
  }
  .signup {
    height: 40px;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .herotextcta {
    max-width: 100%;
  }
}

@media (max-width: 470px) {
  .hero {
    padding: 3rem 2vw;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    row-gap: 20vw;
  }
  .heromaintext {
    font-size: 1.8rem;
  }
  .herosuptext {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .referral {
    font-size: 1rem;
  }
}

@media (min-width: 2900px) {
  .useremail {
    padding-bottom: 20px;
  }
}
