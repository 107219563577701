.footer {
    height: 150px;
    width: 100%;
    background-color: #692AA9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerleftcontainer {
    display: flex;
    flex-flow: row nowrap;
}

.socialheader {
    padding: 0;
    margin: 0;
    color: white;
}

.socialfooter a {
    padding: 4px 0;
    text-decoration: none;
    color: white;
}

.socialfooter {
    padding: 0 6rem;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
}

.contactlink {
    padding-bottom: 4px;
    margin: 0;
    color: white;
}

.contactus a {
    text-decoration: none;
    color: white;
}

.contactus {
    text-align: center;
}

.footerrightcontainer {
    padding: 0 6rem;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: center;
}

.footerlogo img{
    height: 50px;
}

.drapemcorp {
    padding: 0;
    margin: 4px;
    text-align: center;
    color: white;
    font-size: 0.8rem;

}

@media (max-width: 840px) {
    .socialfooter {
        padding: 0 2rem;
    }
    .footerrightcontainer {
        padding: 0 2rem;
    }
}

@media (max-width: 600px) {
    .footer h2 {
        font-size: 1rem;
    }
    .footer a {
        font-size: 0.7rem;
    }
}

@media (max-width: 500px) {
    .footer {
        flex-flow: column nowrap;
        height: 100%;
    }
    .footerleftcontainer {
        flex-flow: column nowrap;
    }
    .footerrightcontainer {
        padding: 0 0 0 1rem;
        padding-bottom: 1rem;
    }
    .socialfooter {
        padding: 1rem 0;
    }
    .contactus {
        padding-bottom: 1rem;
    }
}