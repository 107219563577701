.menu {
  font-weight: 600;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
}

.menuitem {
  display: block;
  color: #692aa9;
  margin: 1vw;
  text-align: right;
  padding: 0;
  text-decoration: none;
  flex-wrap: nowrap;
  flex-shrink: 2;
  transition: color 0.3s linear;
}

.scrolledmenu {
  color: white;
}

.openMenuItem {
  color: white;
}

.menuitem:hover {
  color: coral;
  cursor: pointer;
}

.usercircle {
  align-self: center;
  font-size: 2rem;
  margin-left: 2vw;
}

.usercircle:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .menu {
    background-color: #692aa9;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 295px;
    margin: 0;
    padding: 4rem 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: top;
  }

  .menuitem {
    margin: 0;
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    text-align: left;
    color: white;
    transition: 0.3s linear;
  }

  .menuitem-border {
    align-self: center;
    width: 250px;
    height: 3px;
    background-color: #8142c0;
    z-index: 10;
  }

  .menuitem:hover {
    color: white;
    background-color: #8142c0;
  }

  .menuclick {
    transform: translateX(0);
  }
  .usercircle {
    position: fixed;
    top: 18px;
    left: 10px;
  }
}

/* @media (max-height: 540px) {
  .menuitem {
    padding: 1rem 2rem;
  }
}

@media (max-height: 435px) {
  .menuitem {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
}

@media (max-height: 375px) {
  .menuitem {
    padding: 0.5rem 2rem;
  }
}

@media (max-height: 280px) {
  .menuitem {
    padding: 0.4rem 2rem;
  }
  .menuitem-border {
    display: none;
  }
} */
