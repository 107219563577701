/* design dashboard samples */
/* main sample */
.techpackimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: techpack;
}

.techpackheading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.techpackdesc {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.techpacktext {
  grid-area: techpacktext;
}

.techpack {
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 5px;
  grid-template-areas: "techpacktext techpack";
  align-items: center;
  justify-items: center;
}

@media (max-width: 860px) {
  .techpack {
    grid-template-columns: 1fr;
    grid-template-areas:
      "techpack"
      "techpacktext";
    column-gap: 0;
  }

  .techpackheading {
    font-size: 5vw;
  }
  .techpackdesc {
    font-size: 3vw;
    line-height: normal;
  }
}

/* sub samples */
.textileselectimage {
  box-shadow: 0px 0px 20px grey;
}

.statusimage {
  box-shadow: 0px 0px 20px grey;
}

.textileselectimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: textile;
}

.textiletext {
  margin: 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #2d2d2d;
  line-height: 30px;
  grid-area: textiletext;
}

.statusimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: status;
}

.statustext {
  margin: 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #2d2d2d;
  line-height: 30px;
  grid-area: statustext;
}

.designdashboard {
  padding: 2rem 8vw 1rem 8vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  grid-template-areas:
    "textile status"
    "textiletext statustext";
}

@media (max-width: 860px) {
  .designdashboard {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 5px;
    grid-template-areas:
      "textile"
      "textiletext"
      "status"
      "statustext";
  }

  .textileselectimage {
    box-shadow: 0px 0px 10px grey;
  }

  .statusimage {
    box-shadow: 0px 0px 10px grey;
  }

  .statustext {
    line-height: normal;
  }

  .textiletext {
    line-height: normal;
  }
}

/* Scalability Section */

.scalabilityimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: scalability;
}

.scalabilityheading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.scalabilitydesc {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.scalabilitytext {
  grid-area: scalabilitytext;
}

.scalability {
  padding: 3rem 8vw;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  column-gap: 5px;
  grid-template-areas: "scalabilitytext scalability";
  align-items: center;
  justify-items: center;
}

.market-designs {
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.rocket-ship {
  width: 100%;
  object-fit: cover;
}

.market-designs-text-container {
}

.market-designs-heading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.market-designs-text {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

@media (max-width: 860px) {
  .scalability {
    grid-template-columns: 1fr;
    row-gap: 15px;
    grid-template-areas:
      "scalability"
      "scalabilitytext";
    column-gap: 0;
  }

  .scalabilityheading {
    font-size: 5vw;
  }
  .scalabilitydesc {
    font-size: 3vw;
    line-height: normal;
  }

  .market-designs-heading {
    font-size: 5vw;
  }

  .market-designs-text {
    font-size: 3vw;
    line-height: normal;
  }

  .market-designs {
    grid-template-columns: 1fr;
    row-gap: 15px;
    column-gap: 0;
  }
}

@media (min-width: 2900px) {
  .techpackheading {
    font-size: 1.5vw;
  }
  .techpackdesc {
    font-size: 0.8vw;
  }
  .textiletext {
    font-size: 0.8vw;
  }
  .statustext {
    font-size: 0.8vw;
  }
  .scalabilityheading {
    font-size: 1.5vw;
  }
  .scalabilitydesc {
    font-size: 0.8vw;
  }

  .market-designs-heading {
    font-size: 1.5vw;
  }

  .market-designs-text {
    font-size: 0.8vw;
  }
}
