.navbar {
  padding-top: 15px;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
  background-color: transparent;
  color: #692aa9;
  z-index: 10;
}

/*Sticky property*/
.scrolled {
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  margin: 0;
  background-color:  #692aa9;
  color: #f8f4fb;
  padding-top: 5px;
  padding-bottom: 5px;
}

.logo {
  margin: auto 0;
}

.logo img {
  height: 40px;
}

.logo img:hover {
  cursor: pointer;
}

.comingsoon {
  font-weight: 600;
  letter-spacing: 0.4em;
  justify-content: center;
  padding-left: 2vw;
  padding-right: 1vw;
  margin: 0;
}

@media (max-width:1000px) {
  .navbar {
    justify-content: space-between;
    padding: 0 15px;
  }
  .comingsoon {
    padding-right: 15vw;
  }
}

@media (max-width: 860px) {
  .navbar {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 860px) {
  .comingsoon {
    padding: 0;
  }
}

@media (max-width: 500px) {
  .comingsoon {
    visibility: hidden;
    letter-spacing: 0;
    font-size: 1;
    margin: 0;
  }
}
