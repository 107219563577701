.textinput-field {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-bottom: 2px dashed #707070;
}

.textinput-label {
  color: #707070;
  font-weight: 500;
  font-size: 1.2rem;

}

.textinput {
  outline: none;
  border: none;
  overflow:hidden;
  margin: 0;
  width: 100%;
  padding: 0.50rem 0 0 0;
  background: none;
  color:#8142c0;
  font-size: 1.2rem;
  font-weight: 600;
  transition: border 500ms;
}

.textinput-field::after {
  content: "";
  position: relative;
  display: block;
  height: 4px;
  width: 100%;
  background: #d16dff;
  transform: scaleX(0);
  transform-origin: 0%;
  transition: transform 500ms ease;
  top: 2px;
}

.textinput-field:focus-within {
  border-color: transparent;
}

.textinput-field:focus-within::after {
  border-color: transparent;
  transform: scaleX(1);
}

.textinput-label {
  z-index: -1;
  position: absolute;
  left: 0;
  transform: translateY(0.5rem);
  transform-origin: 0%;
  transition: transform 400ms;
}

.textinput-field:focus-within .textinput-label, 
.textinput:not(:placeholder-shown) + .textinput-label {
  transform: scale(0.75) translateY(-1rem);
}