.seg {
    display: flex;
    padding-top: 30px;
    flex-flow: column nowrap;
    align-items: center;
}

.segline{
    width: 20px;
    height: 3px;
    background-color: #8142c0;
}

.segtitle {
    margin-top: 5px;
    color: #707070;
    font-size: 2vw;
}

.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 0.5vw;
    background: #fff;
    border: 3px solid #692AA9;
    color: #3C3A3A;
    text-align: center;
    font-size: 3vw;
    line-height: 60px;
    font-weight: 600;
  }

.linesep {
    width: 3px;
    height: 20px;
    background-color: #707070;
}

.hiwlabel {
    color: #8142C0;
    margin:0;
    font-size: 2vw;
}

.desc {
    color: #2D2D2D;
    font-size: 1.5vw;
    font-weight: 500;
}

.circle.one {
    grid-area: circleone;
}

.circle.two {
    grid-area: circletwo;
}

.circle.three {
    grid-area: circlethree;
}

.circle.four {
    grid-area: circlefour;
}

.hiwlabel.design {
    grid-area: labeldesign;
}

.hiwlabel.sample {
    grid-area: labelsample;
}

.hiwlabel.shop {
    grid-area: labelshop;
}

.hiwlabel.create {
    grid-area: labelcreate;
    text-align: center;
}

.desc.design {
    grid-area: descdesign;
    text-align: right;
}

.desc.sample {
    grid-area: descsample;
}

.desc.shop {
    grid-area: descshop;
    text-align: right;
}

.desc.create {
    grid-area: desccreate;
}

.palette {
    grid-area: palette;
    font-size: 5vw;
}

.cut {
    grid-area: cut;
    font-size: 5vw;
}

.shopbag {
    grid-area: shopbag;
    font-size: 5vw;
}

.ship {
    grid-area: ship;
    font-size: 5vw;
}

.linesep.one {
    grid-area: linesepone;
}

.linesep.two {
    grid-area: lineseptwo;
}

.linesep.three {
    grid-area: linesepthree;
}

.hiwgrid {
    padding: 0 5vw;
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 3fr; 
    grid-template-rows: auto;
    row-gap: 3px;
    grid-template-areas:
    "descdesign labeldesign circleone palette ."
    '. . linesepone . .'
    ". cut circletwo labelsample descsample"
    '. . lineseptwo . .'
    "descshop labelshop circlethree shopbag ."
    '. . linesepthree . .'
    ". ship circlefour labelcreate desccreate";
    column-gap: 1.5vw;
}

@media (max-width: 860px) {
    .hiwgrid {
        padding: 0 4vw;
        grid-template-columns: 1fr 1fr 3fr; 
        column-gap: 0;
        row-gap: 5px;
        grid-template-areas:
        ". labeldesign ."
        "circleone palette descdesign"
        'linesepone labelsample .'
        "circletwo cut descsample"
        'lineseptwo labelshop .'
        "circlethree shopbag descshop"
        'linesepthree labelcreate .'
        "circlefour ship desccreate";
    }
    .segtitle {
        font-size: 3vw;
    }
    .desc.design {
        text-align: left;
    }
    .desc.shop {
        text-align:left;
    }
    .hiwlabel {
        font-size: 4vw;
    }
    .desc {
        font-size: 3vw;
    }
    .palette {
        font-size: 10vw;
    }
    .cut {
        font-size: 10vw;
    }
    .shopbag {
        font-size: 10vw;
    }
    .ship {
        font-size: 10vw;
    }
    .circle {
        font-size: 5vw;
        height: 8vw;
        width: 8vw;
        line-height: 8vw;
    }
}

@media (min-width: 2900px) {
    .circle {
        font-size: 2vw;
    }
}
