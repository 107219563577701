/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

 
/* .box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
} */

.box {
  color: initial;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: space-around;
  text-align: center;
  position: absolute;
  /* float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  width: 300px;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  
}

@media(max-width: 400px) {
  .box {
    width: 300px;
    height: 300px;
  }
}
 
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5rem;
  color: #4a4a4a;
  cursor: pointer;
}

.popupmessage{
  color: #d16dff;
  font-size: 1.5rem;
}

.popup-paperplane {
  color: #d16dff;
  font-size: 5rem;
}