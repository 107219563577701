.shopsegment {
  padding: 10px 0;
}

/* STYLE */
.shopimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: webshop;
}

.styleheading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.styledesc {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.styletext {
  grid-area: styletext;
}

.style {
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  column-gap: 10px;
  grid-template-areas: "webshop styletext";
  align-items: center;
  justify-items: center;
}

@media (max-width: 860px) {
  .style {
    grid-template-columns: 1fr;
    grid-template-areas:
      "webshop"
      "styletext";
    column-gap: 0;
  }

  .styleheading {
    font-size: 5vw;
  }
  .styledesc {
    font-size: 3vw;
    line-height: normal;
  }
}

/* TAILORED */

.sizehead {
  color: #2d2d2d;
  font-weight: 900;
}

.sizedesc {
  font-weight: 500;
  color: #2d2d2d;
}

.oldfashion {
  /* stay on top of grey border */
  padding-left: 5px;
  z-index: 1;
  color: white;
  font-weight: 600;
}

.tapemeasure img {
  height: 80px;
  object-fit: cover;
}

.apiimage img {
  min-width: 100%;
  object-fit: cover;
}

.apiimage {
  justify-self: right;
  grid-area: apiimg;
  /* stay on top of grey border */
  z-index: 1;
}

.apitextone {
  grid-area: apitxt;
}

.tapemeasurefashion {
  display: flex;
  flex-flow: row nowrap;
  grid-area: tpmfash;
}

.horizontalpurpdash {
  width: 3px;
  height: 30px;
  background-color: #8142c0;
  grid-area: hpdash;
  margin: 0 2rem;
}

.tailorpic img {
  max-height: 390px;
  border-radius: 40px;
}

.tailorpic {
  grid-area: tailorimg;
  justify-self: left;
  z-index: 1;
}

.tailortxtone {
  text-align: right;
  grid-area: tailortxt;
}

.tailoredcatchp {
  text-align: right;
  grid-area: tailoredcp;
  /* stay on top of grey border */
  z-index: 1;
  color: white;
  font-weight: 600;
}

.tapemeasure {
  /* stay on top of grey border */
  z-index: 1;
}

.tpmbckgrd {
  background-color: #c3c1c4;
  width: calc(100vw - (100vw - 100%));
  height: 80px;
  position: absolute;
  left: 0%;
  z-index: 0;
}

.tailoredfit {
  padding: 5rem 5rem 2rem 5rem;
  display: grid;
  grid-template-columns: 2fr 2fr 0.5fr 2fr 2fr;
  grid-template-rows: auto 80px;
  grid-template-areas:
    "apiimg apitxt hpdash tailortxt tailorimg"
    "apiimg tpmfash hpdash tailoredcp tailorimg";
  column-gap: 15px;
  align-items: center;
  justify-items: center;
}

.sustainable-fashion {
  padding: 3rem 8vw;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  column-gap: 10px;
  align-items: center;
  justify-items: center;
}

.sustainable-fashion-text-container {

}

.sustainable-fashion-heading {
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.sustainable-fashion-text {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.sustainable-image {
  max-width: 100%;
  object-fit: cover;
}

@media (max-width: 860px) {

  .sustainable-fashion {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 15px;
  }

  .sustainable-fashion-heading {
    font-size: 5vw;
  }
  .sustainable-fashion-text {
    font-size: 3vw;
    line-height: normal;
  }


}

@media (max-width: 1260px) {
  .horizontalpurpdash {
    width: 30px;
    height: 3px;
    margin: 2rem 0;
  }

  .tailoredfit {
    padding: 3rem 15vw 0 15vw;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
      "apiimg apitxt"
      "apiimg tpmfash"
      "hpdash hpdash"
      "tailortxt tailorimg"
      "tailoredcp tailorimg";
  }

  .apiimage {
    justify-self: center;
  }

  .tailorpic {
    justify-self: center;
    z-index: 2;
  }

  .tailoredcatchp {
    justify-self: right;
    align-self: center;
  }

  .tailoredcatchptxt {
    margin: 20px;
  }

  .catchpbkg {
    background-color: #c3c1c4;
    width: calc(100vw - (100vw - 100%));
    height: 80px;
    position: absolute;
    left: 0%;
    z-index: -1;
  }
}

@media (max-width: 650px) {
  .apiimage img {
    max-width: 130px;
  }

  .tailorpic img {
    max-width: 100%;
  }

  .tailorpic {
    max-width: 70%;
  }

  .tailoredfit {
    padding: 2rem 0;
    column-gap: 0;
  }
}

@media (max-width: 440px) {
  .sizehead {
    font-size: 5vw;
  }

  .sizedesc {
  font-size: 3vw;
  }

  .apiimage img {
    max-width: 25vw;
  }

  .tailorpic {
    max-width: 50vw;
  }
  .tailoredfit {
    padding: 2rem 4vw;
    grid-template-columns: 1fr;
    grid-template-areas:
      "apiimg"
      "apitxt"
      "tpmfash"
      "hpdash"
      "tailorimg"
      "tailortxt"
      "tailoredcp";
  }

}

@media (min-width: 2900px) {
  .styleheading {
    font-size: 1.5vw;
  }
  .styledesc {
    font-size: 0.8vw;
  }
  .sizehead {
    font-size: 1.5vw;
  }
  .sizedesc {
    font-size: 1.5vw;
  }
  .tailoredcatchp {
    font-size: 0.8vw;
  }
  .oldfashion {
    font-size: 0.8vw;
  }

  .sustainable-fashion-heading {
    font-size: 1.5vw;
  }
  .sustainable-fashion-text {
    font-size: 0.8vw;
  }

}
