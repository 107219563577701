@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: "montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.herosegment {
    background-color: #F1EBF8;
}

.shopsegment {
    background-color: #F1EBF8;
}

.prelaunchsegment {
    background-color: #F1EBF8;
}
.navbar {
  padding-top: 15px;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
  background-color: transparent;
  color: #692aa9;
  z-index: 10;
}

/*Sticky property*/
.scrolled {
  position: fixed;
  height: 60px;
  top: 0;
  left: 0;
  margin: 0;
  background-color:  #692aa9;
  color: #f8f4fb;
  padding-top: 5px;
  padding-bottom: 5px;
}

.logo {
  margin: auto 0;
}

.logo img {
  height: 40px;
}

.logo img:hover {
  cursor: pointer;
}

.comingsoon {
  font-weight: 600;
  letter-spacing: 0.4em;
  justify-content: center;
  padding-left: 2vw;
  padding-right: 1vw;
  margin: 0;
}

@media (max-width:1000px) {
  .navbar {
    justify-content: space-between;
    padding: 0 15px;
  }
  .comingsoon {
    padding-right: 15vw;
  }
}

@media (max-width: 860px) {
  .navbar {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 860px) {
  .comingsoon {
    padding: 0;
  }
}

@media (max-width: 500px) {
  .comingsoon {
    visibility: hidden;
    letter-spacing: 0;
    font-size: 1;
    margin: 0;
  }
}

.styledBurger {
  width: 2rem;
  height: 2rem;
  position: relative;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 20;
  cursor: pointer;
}

@media (min-width: 1001px) {
  .styledBurger {
    display: none;
  }
}

.burger {
  width: 2rem;
  height: 0.25rem;
  background-color: #692aa9;
  border-radius: 10px;
  transform-origin: 1px;
  transition: all 0.3s linear;
}

.burger:first-child {
  transform: rotate(0deg);
}

.burger:nth-child(2) {
  opacity: 1;
  transform: translateX(0px);
}

.burger:nth-child(3) {
  transform: rotate(0deg);
}

.burgeropen {
  background-color: white;
}

.burgeropen:first-child {
  transform: rotate(45deg);
}

.burgeropen:nth-child(2) {
  opacity: 0;
  transform: translateX(-20px);
}

.burgeropen:nth-child(3) {
  transform: rotate(-45deg);
}

.scrolledBurger{
  background-color: white;
}


.menu {
  font-weight: 600;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
}

.menuitem {
  display: block;
  color: #692aa9;
  margin: 1vw;
  text-align: right;
  padding: 0;
  text-decoration: none;
  flex-wrap: nowrap;
  flex-shrink: 2;
  transition: color 0.3s linear;
}

.scrolledmenu {
  color: white;
}

.openMenuItem {
  color: white;
}

.menuitem:hover {
  color: coral;
  cursor: pointer;
}

.usercircle {
  align-self: center;
  font-size: 2rem;
  margin-left: 2vw;
}

.usercircle:hover {
  cursor: pointer;
}

@media (max-width: 1000px) {
  .menu {
    background-color: #692aa9;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 295px;
    margin: 0;
    padding: 4rem 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: top;
  }

  .menuitem {
    margin: 0;
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    letter-spacing: 0.2rem;
    text-align: left;
    color: white;
    transition: 0.3s linear;
  }

  .menuitem-border {
    align-self: center;
    width: 250px;
    height: 3px;
    background-color: #8142c0;
    z-index: 10;
  }

  .menuitem:hover {
    color: white;
    background-color: #8142c0;
  }

  .menuclick {
    transform: translateX(0);
  }
  .usercircle {
    position: fixed;
    top: 18px;
    left: 10px;
  }
}

/* @media (max-height: 540px) {
  .menuitem {
    padding: 1rem 2rem;
  }
}

@media (max-height: 435px) {
  .menuitem {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
}

@media (max-height: 375px) {
  .menuitem {
    padding: 0.5rem 2rem;
  }
}

@media (max-height: 280px) {
  .menuitem {
    padding: 0.4rem 2rem;
  }
  .menuitem-border {
    display: none;
  }
} */

/* Popup style */
.auth-box {
  color: #000;
  color: initial;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: space-around;
  text-align: center;
}

.auth-popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.signin-bold {
  color: #8142c0;
  font-size: 1.2rem;
}

.forgot-password {
  color: #cf6679;
  font-weight: 500;
  cursor: pointer;
}

.signup-link {
  color: #d16dff;
  font-weight: 500;
  cursor: pointer;
}

.signInForm {
  height: 45%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
}

.usercircle-signin {
  font-size: 5rem;
  color: #d16dff;
}

.signin-input {
  padding: 100px;
}

.signin-error {
  color: red;
}

.forgot-message-auth{
  color: #d16dff;
}

.signup-span {
  
}

/* .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  } */

.auth-box {
  position: absolute;
  /* float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  width: 250px;
  height: 400px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
}

.auth-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5rem;
  color: #4a4a4a;
  cursor: pointer;
}

.select-popup-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.textinput-field {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border-bottom: 2px dashed #707070;
}

.textinput-label {
  color: #707070;
  font-weight: 500;
  font-size: 1.2rem;

}

.textinput {
  outline: none;
  border: none;
  overflow:hidden;
  margin: 0;
  width: 100%;
  padding: 0.50rem 0 0 0;
  background: none;
  color:#8142c0;
  font-size: 1.2rem;
  font-weight: 600;
  transition: border 500ms;
}

.textinput-field::after {
  content: "";
  position: relative;
  display: block;
  height: 4px;
  width: 100%;
  background: #d16dff;
  transform: scaleX(0);
  transform-origin: 0%;
  transition: transform 500ms ease;
  top: 2px;
}

.textinput-field:focus-within {
  border-color: transparent;
}

.textinput-field:focus-within::after {
  border-color: transparent;
  transform: scaleX(1);
}

.textinput-label {
  z-index: -1;
  position: absolute;
  left: 0;
  transform: translateY(0.5rem);
  transform-origin: 0%;
  transition: transform 400ms;
}

.textinput:not(:-ms-input-placeholder) + .textinput-label {
  transform: scale(0.75) translateY(-1rem);
}

.textinput-field:focus-within .textinput-label, 
.textinput:not(:placeholder-shown) + .textinput-label {
  transform: scale(0.75) translateY(-1rem);
}
/* Popup style */
.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

 
/* .box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
} */

.box {
  color: #000;
  color: initial;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: space-around;
  text-align: center;
  position: absolute;
  /* float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
  width: 300px;
  height: 300px;
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  
}

@media(max-width: 400px) {
  .box {
    width: 300px;
    height: 300px;
  }
}
 
.close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 1.5rem;
  color: #4a4a4a;
  cursor: pointer;
}

.popupmessage{
  color: #d16dff;
  font-size: 1.5rem;
}

.popup-paperplane {
  color: #d16dff;
  font-size: 5rem;
}
.purpdash {
  width: 2rem;
  height: 0.2rem;
  background-color: #692aa9;
}

/* .loader {
  margin: 0;
  padding: 0;
} */

.heromaintext {
  /* font-size: 2.2vw; */
  font-size: 2em;
  font-weight: 800;
  color: #313131;
  margin: 10px 0;
}

.herosuptext {
  /* font-size: 1.5vw; */
  font-size: 1.3rem;
  font-weight: 500;
  color: #707070;
  line-height: 1.2em;
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 1vw;
}

.referral {
  font-size: 0.9rem;
  font-weight: 500;
  /* color: #692aa9; */
  color: #d16dff;
}

.usertype {
  padding-right: 0.5vw;
  align-self: center;
}

.useremail {
  padding-bottom: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  flex-basis: auto;
}

.emailinput {
  width: 100%;
  z-index: 1;
}

.signup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  width: 100%;
  /* height: 2.2vw; */
  height: 30px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8142c0;
  background: #8142c0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

.signup:focus {
  outline: none;
}

.signup:hover {
  background: #9952e1;
  border: 3px solid #9952e1;
}

.signup span {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.herotextcta {
  max-width: 85%;
  max-height: 100%;
  grid-area: herotextcta;
}

.heroimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: heroimage;
}

.hero {
  padding: 4vw 6vw 6vw 6vw;
  height: 70vh;
  display: grid;
  grid-template-columns: minmax(400px, 0.8fr) minmax(400px, 1fr);
  grid-template-areas: "herotextcta heroimage";
  align-items: center;
  justify-items: center;
}

.referral {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .hero {
    padding: 2rem;
  }
  .herotextcta {
    max-width: 90%;
  }
  .heromaintext {
    font-size: 1.5em;
  }
  .herosuptext {
    font-size: 1rem;
  }
  .referral {
    font-size: 0.9rem;
  }
}

@media (max-width: 860px) {
  .hero {
    height: 100%;
    padding: 3rem 10vw;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-template-areas:
      "heroimage"
      "herotextcta";
    grid-row-gap: 70px;
    row-gap: 70px;
    align-items: center;
    justify-items: center;
  }
  .herotextcta {
    max-width: 100%;
  }
  .heromaintext {
    font-size: 2em;
  }
  .herosuptext {
    font-size: 1.3rem;
    line-height: 40px;
  }
  .referral {
    font-size: 1em;
  }
  .signup {
    height: 40px;
    font-size: 20px;
  }
}

@media (max-width: 600px) {
  .herotextcta {
    max-width: 100%;
  }
}

@media (max-width: 470px) {
  .hero {
    padding: 3rem 2vw;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-row-gap: 20vw;
    row-gap: 20vw;
  }
  .heromaintext {
    font-size: 1.8rem;
  }
  .herosuptext {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .referral {
    font-size: 1rem;
  }
}

@media (min-width: 2900px) {
  .useremail {
    padding-bottom: 20px;
  }
}

.seg {
    display: flex;
    padding-top: 30px;
    flex-flow: column nowrap;
    align-items: center;
}

.segline{
    width: 20px;
    height: 3px;
    background-color: #8142c0;
}

.segtitle {
    margin-top: 5px;
    color: #707070;
    font-size: 2vw;
}

.circle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 0.5vw;
    background: #fff;
    border: 3px solid #692AA9;
    color: #3C3A3A;
    text-align: center;
    font-size: 3vw;
    line-height: 60px;
    font-weight: 600;
  }

.linesep {
    width: 3px;
    height: 20px;
    background-color: #707070;
}

.hiwlabel {
    color: #8142C0;
    margin:0;
    font-size: 2vw;
}

.desc {
    color: #2D2D2D;
    font-size: 1.5vw;
    font-weight: 500;
}

.circle.one {
    grid-area: circleone;
}

.circle.two {
    grid-area: circletwo;
}

.circle.three {
    grid-area: circlethree;
}

.circle.four {
    grid-area: circlefour;
}

.hiwlabel.design {
    grid-area: labeldesign;
}

.hiwlabel.sample {
    grid-area: labelsample;
}

.hiwlabel.shop {
    grid-area: labelshop;
}

.hiwlabel.create {
    grid-area: labelcreate;
    text-align: center;
}

.desc.design {
    grid-area: descdesign;
    text-align: right;
}

.desc.sample {
    grid-area: descsample;
}

.desc.shop {
    grid-area: descshop;
    text-align: right;
}

.desc.create {
    grid-area: desccreate;
}

.palette {
    grid-area: palette;
    font-size: 5vw;
}

.cut {
    grid-area: cut;
    font-size: 5vw;
}

.shopbag {
    grid-area: shopbag;
    font-size: 5vw;
}

.ship {
    grid-area: ship;
    font-size: 5vw;
}

.linesep.one {
    grid-area: linesepone;
}

.linesep.two {
    grid-area: lineseptwo;
}

.linesep.three {
    grid-area: linesepthree;
}

.hiwgrid {
    padding: 0 5vw;
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr 3fr; 
    grid-template-rows: auto;
    grid-row-gap: 3px;
    row-gap: 3px;
    grid-template-areas:
    "descdesign labeldesign circleone palette ."
    '. . linesepone . .'
    ". cut circletwo labelsample descsample"
    '. . lineseptwo . .'
    "descshop labelshop circlethree shopbag ."
    '. . linesepthree . .'
    ". ship circlefour labelcreate desccreate";
    grid-column-gap: 1.5vw;
    -webkit-column-gap: 1.5vw;
            column-gap: 1.5vw;
}

@media (max-width: 860px) {
    .hiwgrid {
        padding: 0 4vw;
        grid-template-columns: 1fr 1fr 3fr; 
        grid-column-gap: 0; 
        -webkit-column-gap: 0; 
                column-gap: 0;
        grid-row-gap: 5px;
        row-gap: 5px;
        grid-template-areas:
        ". labeldesign ."
        "circleone palette descdesign"
        'linesepone labelsample .'
        "circletwo cut descsample"
        'lineseptwo labelshop .'
        "circlethree shopbag descshop"
        'linesepthree labelcreate .'
        "circlefour ship desccreate";
    }
    .segtitle {
        font-size: 3vw;
    }
    .desc.design {
        text-align: left;
    }
    .desc.shop {
        text-align:left;
    }
    .hiwlabel {
        font-size: 4vw;
    }
    .desc {
        font-size: 3vw;
    }
    .palette {
        font-size: 10vw;
    }
    .cut {
        font-size: 10vw;
    }
    .shopbag {
        font-size: 10vw;
    }
    .ship {
        font-size: 10vw;
    }
    .circle {
        font-size: 5vw;
        height: 8vw;
        width: 8vw;
        line-height: 8vw;
    }
}

@media (min-width: 2900px) {
    .circle {
        font-size: 2vw;
    }
}

.shopsegment {
  padding: 10px 0;
}

/* STYLE */
.shopimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: webshop;
}

.styleheading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.styledesc {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.styletext {
  grid-area: styletext;
}

.style {
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-template-areas: "webshop styletext";
  align-items: center;
  justify-items: center;
}

@media (max-width: 860px) {
  .style {
    grid-template-columns: 1fr;
    grid-template-areas:
      "webshop"
      "styletext";
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .styleheading {
    font-size: 5vw;
  }
  .styledesc {
    font-size: 3vw;
    line-height: normal;
  }
}

/* TAILORED */

.sizehead {
  color: #2d2d2d;
  font-weight: 900;
}

.sizedesc {
  font-weight: 500;
  color: #2d2d2d;
}

.oldfashion {
  /* stay on top of grey border */
  padding-left: 5px;
  z-index: 1;
  color: white;
  font-weight: 600;
}

.tapemeasure img {
  height: 80px;
  object-fit: cover;
}

.apiimage img {
  min-width: 100%;
  object-fit: cover;
}

.apiimage {
  justify-self: right;
  grid-area: apiimg;
  /* stay on top of grey border */
  z-index: 1;
}

.apitextone {
  grid-area: apitxt;
}

.tapemeasurefashion {
  display: flex;
  flex-flow: row nowrap;
  grid-area: tpmfash;
}

.horizontalpurpdash {
  width: 3px;
  height: 30px;
  background-color: #8142c0;
  grid-area: hpdash;
  margin: 0 2rem;
}

.tailorpic img {
  max-height: 390px;
  border-radius: 40px;
}

.tailorpic {
  grid-area: tailorimg;
  justify-self: left;
  z-index: 1;
}

.tailortxtone {
  text-align: right;
  grid-area: tailortxt;
}

.tailoredcatchp {
  text-align: right;
  grid-area: tailoredcp;
  /* stay on top of grey border */
  z-index: 1;
  color: white;
  font-weight: 600;
}

.tapemeasure {
  /* stay on top of grey border */
  z-index: 1;
}

.tpmbckgrd {
  background-color: #c3c1c4;
  width: calc(100vw - (100vw - 100%));
  height: 80px;
  position: absolute;
  left: 0%;
  z-index: 0;
}

.tailoredfit {
  padding: 5rem 5rem 2rem 5rem;
  display: grid;
  grid-template-columns: 2fr 2fr 0.5fr 2fr 2fr;
  grid-template-rows: auto 80px;
  grid-template-areas:
    "apiimg apitxt hpdash tailortxt tailorimg"
    "apiimg tpmfash hpdash tailoredcp tailorimg";
  grid-column-gap: 15px;
  -webkit-column-gap: 15px;
          column-gap: 15px;
  align-items: center;
  justify-items: center;
}

.sustainable-fashion {
  padding: 3rem 8vw;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: center;
  justify-items: center;
}

.sustainable-fashion-text-container {

}

.sustainable-fashion-heading {
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.sustainable-fashion-text {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.sustainable-image {
  max-width: 100%;
  object-fit: cover;
}

@media (max-width: 860px) {

  .sustainable-fashion {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .sustainable-fashion-heading {
    font-size: 5vw;
  }
  .sustainable-fashion-text {
    font-size: 3vw;
    line-height: normal;
  }


}

@media (max-width: 1260px) {
  .horizontalpurpdash {
    width: 30px;
    height: 3px;
    margin: 2rem 0;
  }

  .tailoredfit {
    padding: 3rem 15vw 0 15vw;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: auto;
    grid-template-areas:
      "apiimg apitxt"
      "apiimg tpmfash"
      "hpdash hpdash"
      "tailortxt tailorimg"
      "tailoredcp tailorimg";
  }

  .apiimage {
    justify-self: center;
  }

  .tailorpic {
    justify-self: center;
    z-index: 2;
  }

  .tailoredcatchp {
    justify-self: right;
    align-self: center;
  }

  .tailoredcatchptxt {
    margin: 20px;
  }

  .catchpbkg {
    background-color: #c3c1c4;
    width: calc(100vw - (100vw - 100%));
    height: 80px;
    position: absolute;
    left: 0%;
    z-index: -1;
  }
}

@media (max-width: 650px) {
  .apiimage img {
    max-width: 130px;
  }

  .tailorpic img {
    max-width: 100%;
  }

  .tailorpic {
    max-width: 70%;
  }

  .tailoredfit {
    padding: 2rem 0;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }
}

@media (max-width: 440px) {
  .sizehead {
    font-size: 5vw;
  }

  .sizedesc {
  font-size: 3vw;
  }

  .apiimage img {
    max-width: 25vw;
  }

  .tailorpic {
    max-width: 50vw;
  }
  .tailoredfit {
    padding: 2rem 4vw;
    grid-template-columns: 1fr;
    grid-template-areas:
      "apiimg"
      "apitxt"
      "tpmfash"
      "hpdash"
      "tailorimg"
      "tailortxt"
      "tailoredcp";
  }

}

@media (min-width: 2900px) {
  .styleheading {
    font-size: 1.5vw;
  }
  .styledesc {
    font-size: 0.8vw;
  }
  .sizehead {
    font-size: 1.5vw;
  }
  .sizedesc {
    font-size: 1.5vw;
  }
  .tailoredcatchp {
    font-size: 0.8vw;
  }
  .oldfashion {
    font-size: 0.8vw;
  }

  .sustainable-fashion-heading {
    font-size: 1.5vw;
  }
  .sustainable-fashion-text {
    font-size: 0.8vw;
  }

}

/* design dashboard samples */
/* main sample */
.techpackimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: techpack;
}

.techpackheading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.techpackdesc {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.techpacktext {
  grid-area: techpacktext;
}

.techpack {
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  grid-template-areas: "techpacktext techpack";
  align-items: center;
  justify-items: center;
}

@media (max-width: 860px) {
  .techpack {
    grid-template-columns: 1fr;
    grid-template-areas:
      "techpack"
      "techpacktext";
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .techpackheading {
    font-size: 5vw;
  }
  .techpackdesc {
    font-size: 3vw;
    line-height: normal;
  }
}

/* sub samples */
.textileselectimage {
  box-shadow: 0px 0px 20px grey;
}

.statusimage {
  box-shadow: 0px 0px 20px grey;
}

.textileselectimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: textile;
}

.textiletext {
  margin: 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #2d2d2d;
  line-height: 30px;
  grid-area: textiletext;
}

.statusimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: status;
}

.statustext {
  margin: 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #2d2d2d;
  line-height: 30px;
  grid-area: statustext;
}

.designdashboard {
  padding: 2rem 8vw 1rem 8vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-template-areas:
    "textile status"
    "textiletext statustext";
}

@media (max-width: 860px) {
  .designdashboard {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
    grid-row-gap: 5px;
    row-gap: 5px;
    grid-template-areas:
      "textile"
      "textiletext"
      "status"
      "statustext";
  }

  .textileselectimage {
    box-shadow: 0px 0px 10px grey;
  }

  .statusimage {
    box-shadow: 0px 0px 10px grey;
  }

  .statustext {
    line-height: normal;
  }

  .textiletext {
    line-height: normal;
  }
}

/* Scalability Section */

.scalabilityimage img {
  max-width: 100%;
  object-fit: cover;
  grid-area: scalability;
}

.scalabilityheading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.scalabilitydesc {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

.scalabilitytext {
  grid-area: scalabilitytext;
}

.scalability {
  padding: 3rem 8vw;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-column-gap: 5px;
  -webkit-column-gap: 5px;
          column-gap: 5px;
  grid-template-areas: "scalabilitytext scalability";
  align-items: center;
  justify-items: center;
}

.market-designs {
  padding: 0 8vw;
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.rocket-ship {
  width: 100%;
  object-fit: cover;
}

.market-designs-text-container {
}

.market-designs-heading {
  margin: 0;
  font-weight: 900;
  font-size: 1.5rem;
  color: #2d2d2d;
}

.market-designs-text {
  margin-top: 5px;
  font-weight: 600;
  font-size: 1rem;
  color: #2d2d2d;
  line-height: 30px;
}

@media (max-width: 860px) {
  .scalability {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-template-areas:
      "scalability"
      "scalabilitytext";
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }

  .scalabilityheading {
    font-size: 5vw;
  }
  .scalabilitydesc {
    font-size: 3vw;
    line-height: normal;
  }

  .market-designs-heading {
    font-size: 5vw;
  }

  .market-designs-text {
    font-size: 3vw;
    line-height: normal;
  }

  .market-designs {
    grid-template-columns: 1fr;
    grid-row-gap: 15px;
    row-gap: 15px;
    grid-column-gap: 0;
    -webkit-column-gap: 0;
            column-gap: 0;
  }
}

@media (min-width: 2900px) {
  .techpackheading {
    font-size: 1.5vw;
  }
  .techpackdesc {
    font-size: 0.8vw;
  }
  .textiletext {
    font-size: 0.8vw;
  }
  .statustext {
    font-size: 0.8vw;
  }
  .scalabilityheading {
    font-size: 1.5vw;
  }
  .scalabilitydesc {
    font-size: 0.8vw;
  }

  .market-designs-heading {
    font-size: 1.5vw;
  }

  .market-designs-text {
    font-size: 0.8vw;
  }
}

.referralcta {
    margin: 50px;
}

.referralmaintext {
    margin: 5px 0;
    font-size: 2rem;
    font-weight: 800;
    color: #2D2D2D;
}

.referralsuptext {
    margin: 0 0 15px 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #2D2D2D;
}

/* grid-area classification for tier descriptions */
.bullhorn {
    font-size: 3rem;
    grid-area: bullhorn;
}

.tieroneheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tieronedesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tieronetext {
    display: flex;
    flex-flow: column nowrap;
    grid-area: tierone;
    align-items: center;
}

.tiertwousername {
    font-size: 1.3rem;
    color: #4A47A3;
    grid-area: tiertwousername;
}

.tiertwoheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tiertwodesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}
.tiertwotext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tiertwo;
}

.faatom {
    font-size: 3rem;
    grid-area: faatom;
}

.tierthreeheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tierthreedesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierthreetext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierthree;
}

.tierfourheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tierfourdesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierfourtext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierfour;
}

.fatags {
    font-size: 3rem;
    grid-area: fatags;
}

.tierfiveheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tierfivedesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierfivetext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierfive;
}

.faruler {
    font-size: 3rem;
    grid-area: faruler;
}

.tiersixheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tiersixdesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tiersixtext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tiersix;
}

.fapercent {
    font-size: 3rem;
    grid-area: fapercent;
}

.tiersevenheader {
    margin: 0;
    color: #8142C0;
    font-size: 1.5rem;
    font-weight: 800;
}

.tiersevendesc {
    color: #2D2D2D;
    font-weight: 500;
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
}

.tierseventext {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    grid-area: tierseven;
}

.fagift {
    font-size: 5rem;
    grid-area: fagift;
    color:  #d16dff;
}

.referraltiers {
    padding-top: 15px;
    margin-bottom: 50px;
    display: grid;
    border-radius: 50px;
    border: 3px solid #d16dff;
    background-color: transparent;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 20px;
    row-gap: 20px;
    grid-template-areas: 
    "bullhorn tiertwousername faatom"
    "tierone tiertwo tierthree"
    "faruler fapercent fatags"
    "tierfour tierfive tiersix"
    ". fagift ."
    ". tierseven .";
    justify-items: center;
    grid-area: referraltiers;
    align-items: center;
}

.prelaunchcontainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
}

/* @media (max-width: 1000px) {
    .prelaunchcontainer {
        padding: 1rem 2rem;
    }
} */

/* @media (max-width: 860px) {
    .prelaunchcontainer {
        padding: 1rem 5vw;
        grid-template-columns: 1fr;
        column-gap: 0;
        row-gap: 20px;
        grid-template-areas: 
        "referraltiers"
        "referralcta";
    }
} */

@media (max-width: 560px) {
    .tieroneheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tieronedesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tieronetext {
        display: flex;
        flex-flow: column nowrap;
        grid-area: tierone;
        align-items: center;
    }
    
    
    .tiertwoheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tiertwodesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    .tiertwotext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tiertwo;
    }
    
    .tierthreeheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tierthreedesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierthreetext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierthree;
    }

    .tierfourheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tierfourdesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierfourtext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierfour;
    }

    .tierfiveheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tierfivedesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierfivetext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierfive;
    }

    .tiersixheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tiersixdesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tiersixtext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tiersix;
    }

    .tiersevenheader {
        margin: 0;
        color: #8142C0;
        font-size: 1rem;
        font-weight: 800;
    }
    
    .tiersevendesc {
        color: #2D2D2D;
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
        margin: 1rem;
    }
    
    .tierseventext {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        grid-area: tierseven;
    }
}


@media (max-width: 400px) {

    .referraltiers {
        grid-template-columns: repeat(2, 1fr);
    }
    .referralcta {
        margin: 10px;
    }
}

@media (max-width: 370px) {
    .bullhorn {
        font-size: 2.5rem;
    }
    .fatags {
        font-size: 2.5rem;
    }
    .faatom {
        font-size: 2.5rem;
    }
    .faruler {
        font-size: 2.5rem;
    }
    .fapercent {
        font-size: 2.5rem;
    }
    .fagift {
        font-size: 3rem;
    }
    .tiertwousername {
        font-size: 1rem;
    }
    .tieronedesc {
        font-size: 0.8rem;
    }
    .tiertwodesc{
        font-size: 0.8rem;
    }
    .tierthreedesc {
        font-size: 0.8rem;
    }
    .tierfourdesc {
        font-size: 0.8rem;
    }
    .tierfivedesc {
        font-size: 0.8rem;
    }
    .tiersixdesc {
        font-size: 0.8rem;
    }
    .tiersevendesc {
        font-size: 0.8rem;
    }
}

@media (min-width: 2900px) {
    .referralmaintext {
        font-size: 1.5vw;
    }
    .referralsuptext {
        font-size: 0.8vw;
        line-height: 2.5rem;
    }
    .bullhorn {
        font-size: 3vw;
    }
    .tiertwousername {
        font-size: 1.5vw;
    }
    .fatags {
        font-size: 3vw;
    }
    .tieroneheader {
        font-size: 1.5vw;
    }
    .tiertwoheader {
        font-size: 1.5vw;
    }
    .tierthreeheader {
        font-size: 1.5vw;
    }
    .tierfourheader {
        font-size: 1.5vw;
    }
    .tierfiveheader {
        font-size: 1.5vw;
    }
    .tiersixheader {
        font-size: 1.5vw;
    }
    .tieronedesc {
        font-size: 0.8vw;
    }
    .tiertwodesc {
        font-size:0.8vw;
    }
    .tierthreedesc {
        font-size: 0.8vw;
    }
    .tierfourdesc {
        font-size: 0.8vw;
    }
    .tierfivedesc {
        font-size: 0.8vw;
    }
    .tiersixdesc {
        font-size: 0.8vw;
    }
    .tiersevendesc {
        font-size: 0.8vw;
    }
}
.getintouch {
  grid-column: auto / span 2;
  margin-top: 5px;
  width: 100%;
  height: 2.5vw;
  line-height: 1;
  font-size: 1.5vw;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8142c0;
  background: #8142c0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

.getintouch:focus {
  outline: none;
}

.getintouch:hover {
  background: #9952e1;
  border: 3px solid #9952e1;
}

.getintouch span {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s;
}

.productionimage img {
  border-radius: 50px;
  max-width: 100%;
  object-fit: cover;
}

.productionimage {
  grid-area: productionimage;
}

.callforsuppliers {
  font-size: 1rem;
  font-weight: 500;
  color: #2d2d2d;
  text-align: center;
  grid-area: callforsuppliers;
}

.supplierform {
  border-radius: 50px;
  padding: 1rem;
  display: grid;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}

.supplierformcontainer {
  grid-area: supplierformcontainer;
}

.suppliercontainer {
  margin-bottom: 2rem;
  padding: 0 3rem;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  grid-template-areas: "productionimage supplierformcontainer";
  justify-items: stretch;
  align-items: center;
}

@media (max-width: 860px) {
  .suppliercontainer {
    padding: 1rem 2rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "productionimage"
      "supplierformcontainer";
  }
  .getintouch {
    height: 40px;
    font-size: 20px;
  }
}

@media (max-width: 570px) {
  .suppliercontainer {
    padding: 1rem 0.5rem;
  }
  .supplierform {
    display: flex;
    flex-flow: column nowrap;
  }
}

@media (min-width: 2900px) {
  .callforsuppliers {
    font-size: 1vw;
  }
}
.footer {
    height: 150px;
    width: 100%;
    background-color: #692AA9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerleftcontainer {
    display: flex;
    flex-flow: row nowrap;
}

.socialheader {
    padding: 0;
    margin: 0;
    color: white;
}

.socialfooter a {
    padding: 4px 0;
    text-decoration: none;
    color: white;
}

.socialfooter {
    padding: 0 6rem;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
}

.contactlink {
    padding-bottom: 4px;
    margin: 0;
    color: white;
}

.contactus a {
    text-decoration: none;
    color: white;
}

.contactus {
    text-align: center;
}

.footerrightcontainer {
    padding: 0 6rem;
    display: flex;
    flex-flow: column nowrap;
    align-content: center;
    justify-content: center;
}

.footerlogo img{
    height: 50px;
}

.drapemcorp {
    padding: 0;
    margin: 4px;
    text-align: center;
    color: white;
    font-size: 0.8rem;

}

@media (max-width: 840px) {
    .socialfooter {
        padding: 0 2rem;
    }
    .footerrightcontainer {
        padding: 0 2rem;
    }
}

@media (max-width: 600px) {
    .footer h2 {
        font-size: 1rem;
    }
    .footer a {
        font-size: 0.7rem;
    }
}

@media (max-width: 500px) {
    .footer {
        flex-flow: column nowrap;
        height: 100%;
    }
    .footerleftcontainer {
        flex-flow: column nowrap;
    }
    .footerrightcontainer {
        padding: 0 0 0 1rem;
        padding-bottom: 1rem;
    }
    .socialfooter {
        padding: 1rem 0;
    }
    .contactus {
        padding-bottom: 1rem;
    }
}
.register-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #692aa9;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.large-hook {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.large-hook img {
  height: 700px;
}

.form-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-form-container {
  position: relative;
  border-radius: 50px;
  height: 500px;
  width: 70%;
  padding: 30px 50px;
  background-color: #e8d1ff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.home-link-container {
  position: absolute;
  top: 25px;
  left: 50px;
}

.home-link {
  text-decoration: none;
  color:  #d16dff;
  font-weight: 500;
}

.register-heading {
  color: #2a2a2a;
  font-size: 2rem;
  margin: 0;
  padding: 0;
}

.email-container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.email-icon-register {
  font-size: 3rem;
  color: #d16dff;
}

.registering-email {
  margin: 10px;
}

.register-form {
  height: 80%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  position: relative;
  z-index: 1;
}

.register-button {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-top: 5px;
  width: 100%;
  /* height: 2.2vw; */
  height: 30px;
  line-height: 1;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 1px;
  border: 3px solid #8142c0;
  background: #8142c0;
  color: white;
  border-radius: 40px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.35s;
}

.register-button:focus {
  outline: none;
}

.register-button:hover {
  background: #9952e1;
  border: 3px solid #9952e1;
}

.span-error-register {
  color: red;
  font-size: 0.85rem;
  text-align: start;
}

@media (max-width: 1200px) {
  .large-hook {
    display: none;
  }

  .form-container {
    width: 90%;
  }

  .register-form-container {
    height: 600px;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .register-form-container {
    border-radius: 10px;
    padding: 0px 20px;
    height: 520px;
    width: 100%;
  }

  .home-link-container {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .registering-email {
    margin: 10px;
    font-size: 1.1rem;
  }
}


@media(max-height:660px) {
  .register-bg {
    position: static;
  }
}

.country-selector {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.country-input {
  margin-right: 10px;
  font-weight: 500;
  color: #707070;
  font-size: 1.2rem;
}
.gohome {
  color: #d16dff;
  cursor: pointer;
  font-size: 1rem;
}

.dashboard-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #692aa6;
}

.dashboard {
  width: 100%;
}

.dashboard-logo {
}

.dashboard-logo img {
  height: 70px;
  /* margin: 10px 50px; */
}

.dashboard-user-account {
  /* margin: 0px 50px; */
  font-size: 1rem;
  font-weight: 500;
  color: lightgrey;
  cursor: pointer;
}

.dashboard-user-greeting {
  margin: 10px 20px 40px 20px;
  padding: 0px;
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-align: center;
}

.dashboard-heading {
  width: 80%;
  margin: 20px 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dashboard-heading-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-tier-progression {
  margin: 0 auto auto auto;
  padding: 20px;
  border-radius: 50px;
  border: 2px solid white;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: flex;
  flex-flow: column nowrap;
  justify-items: center;
  align-items: center;
}

.dashboard-progress{
  color: #cf6679;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-size: 2rem;
  margin: 0;
  margin-bottom: 40px;
}

.dashboard-tiers {
  display: grid;
  grid-template-columns: repeat(3, 250px);
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 40px;
  row-gap: 40px;
  justify-items: center;
  align-items: center;
}

.dashboard-tier-container {
  display: grid;
  grid-template-rows: 50px 70px 40px;
  justify-items: center;
  align-items: center;
}

.dashboard-tier.icon {
  color: #8c8c8c;
  font-size: 3rem;
}

.dashboard-tier.username {
  color: #8c8c8c;
  font-size: 1rem;
}

.dashboard-tier.heading {
  color: white;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.dashboard-tier.requirement {
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}

.dashboard-tier-link {
  margin: auto 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.dashboard-user-link {
  color: white;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.dashboard-link-header {
  text-align: center;
}

.user-shareable-link {
  color: #cf6679;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-shareable-link:hover {
  transform: scale(1.01);
}

.user-shareable-link.container {
  cursor: pointer;
  height: 100px;
  width: 600px;
  border-radius: 30px;
  border: 3px solid #d16dff;
  background-color: #e8d1ff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.user-shareable-link.container:hover {
  background-color: #f1e2ff;
}

.Server-Message{
  margin-top: 10px;
  color: #d16dff;
}

.dashboard-copy-alert{
  position: absolute;
  top: 0;
  right: 0;
  height: 25px;
  width: 300px;
  background-color: #dcffcc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clipboard-message{
  color: grey;
  font-size: 1.1rem;
  font-weight: 600;
}

.social-share-container{
  display: flex;
  flex-flow: row nowrap;
}

.dashboard-count-container {
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.dashboard-count-message {
  margin: 0 10px;
}

.dasboard-referral-count {
  color: #dcffcc;
  margin: 0 10px;
  font-weight: 900;
  font-size: 2.5rem;
}

.dashboard-username-container {
  position: relative;
  height: 150px;
  width: 600px;
  border-radius: 30px;
  border: 3px solid #d16dff;
  background-color: #e8d1ff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.disabled-overlay {
  position: absolute;
  border: 3px solid #d16dff;
  border-radius: 30px;
  width: 600px;
  height: 150px;
  z-index: 3;
}

.disabled-overlay:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dashboard-update-username {
  display: flex;
  flex-flow: row nowrap;
}

.dashboard-username-header {
  margin-bottom: 15px;
  margin-top: 0px;
  text-align: center;
  color: #692aa6;
  font-weight: 500;
  font-size: 1.2rem;
}

.username-button {
  margin-left: 20px;
  width: 120px;
  height: 35px;
  outline: none;
  background-color: #692aa6;
  text-decoration: none;
  border-radius: 15px;
  border: none;
  color: white;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.username-button:hover {
  background-color: #8142c0;
}

.username-button:active {
  background-color: #8142c0;
}

@media (max-width: 1460px) {
  .dashboard-container {
    height: auto;
  }

  .dashboard-tier-link {
    margin-bottom: 100px;
  }

    .dashboard-tier-progression {
margin-bottom: 50px;
  }
}

@media (max-width: 900px) {
  .dashboard-tiers {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-tier-progression {
    width: 100%;
      }
}

@media (max-width: 625px) {
  .dashboard-tier.icon {
    font-size: 2.5rem;
  }

  .dashboard-tier.username {
    font-size: 0.8rem;
  }

  .dashboard-tier.heading {
    font-size: 0.9rem;
  }

  .dashboard-tier.requirement {
    font-size: 1rem;
  }

  .user-shareable-link.container {
    width: 90%;
  }

  .dashboard-username-container {
    width: 90%;
    margin: 10px;
  }

  .dashboard-update-username {
    margin: 0 10px;
  }

  .disabled-overlay {
    width: 100%;
  }

  .user-shareable-link {
    font-size: 1rem;
  }

  .dashboard-username-header {
    font-size: 1rem;
  }

  .dashboard-user-greeting {
    font-size: 1.5rem;
  }
}

@media (max-width: 420px) {
  .dashboard-logo img {
    height: 50px;
  }

  .user-shareable-link {
    font-size: 0.9rem;
  }

  .user-shareable-link.container {
    height: 70px;
  }
}

@media (max-width: 340px) {
  .user-shareable-link {
    font-size: 0.8rem;
  }
}

@media(min-width: 2200px) {
  .dashboard-tier-link {
    justify-content: center;
  }

  .dashboard-user-link {
    margin: 0 100px;
  }

  .dashboard-tier-progression{
      margin: 0 100px;
  }
}

.message-reset{
    color: #d16dff;
}
.notfound {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #692aa9;
}

.notfound-contents {
    margin: 50px;
}

.notfound-header {
  font-size: 3rem;
  color: white;
}

.notfound-link {
  color: white;
}

